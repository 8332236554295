import {
  AnalyticWidgetItemBreakdownByAgeAndGender,
  AnalyticWidgetItemBreakdownByNumberOfChildren,
  AnalyticWidgetItemBreakdownBySECClass,
  AnalyticWidgetItemDeviceUsage,
  AnalyticWidgetItemOverallScorecard,
  AnalyticWidgetItemProps,
  AnalyticWidgetItemReachabilityByChannels,
  AnalyticWidgetItemTopViewCategorise,
  AnalyticWidgetItemUnderConstruction,
  AnalyticWidgetItemVisitByLocation,
  formatBreakdownByAgeAndGender,
  formatBreakdownByNumberOfChildren,
  formatBreakdownBySECClass,
  formatDeviceUsage,
  formatOverallScorecard,
  formatReachabilityByChannels,
  formatTopViewCategorise
} from '../AnalyticWidgetItem'
import {
  AnalyticTemplateWidgetItem,
  AnalyticTemplateWidgetItemDataParams,
  AnalyticWidgetItem
} from 'interfaces/analytic'
import {
  DecoratedGrid,
  MuuriComponentProps
} from 'muuri-react/dist/types/interfaces'
import { Dispatch, FC, SetStateAction, createContext, useContext } from 'react'

export type AnalyticWidgetMuuriContextProps = {
  grid?: DecoratedGrid
  dataQuery: Record<string, any>
  setDataQuery?: Dispatch<SetStateAction<Record<string, any>>>
  query?: AnalyticTemplateWidgetItemDataParams
  onChangeItem?: (item: AnalyticTemplateWidgetItem) => void
  segment_sites?: { id: number; name: string }[]
}
export const AnalyticWidgetMuuriContext =
  createContext<AnalyticWidgetMuuriContextProps>({
    dataQuery: {},
    setDataQuery() {
      //
    },
    query: {},
    onChangeItem(item) {
      console.log('** AnalyticWidgetMuuriContext : ', item)
    },
    segment_sites: []
  })
export const useAnalyticWidgetMuuri = () => {
  return useContext(AnalyticWidgetMuuriContext)
}

export const ANALYTIC_WIDGET_MUURI_OPTIONS: MuuriComponentProps = {
  forceSync: false,
  containerClass: 'analyticWidgetMuuri--container',
  addOptions: { show: true },
  dragEnabled: true,
  dragStartPredicate: {
    handle: '.analyticWidgetMuuriItem--handle'
  },
  dragPlaceholder: {
    enabled: true,
    createElement: function (item: any) {
      return item.getElement().cloneNode(true)
    }
  },
  propsToData: (props) => {
    return props
  }
}

export const ANALYTIC_WIDGET_ITEM_KEY = {
  OVERALL_SCORECARD: 'Overall Scorecard',
  REACHABILITY_BY_CHANNELS: 'Reachability by channels',
  BREAKDOWN_BY_AGE_AND_GENDER: 'Breakdown by Age and Gender',
  BREAKDOWN_BY_SEC_CLASS: 'Breakdown by SEC Class',
  ACCOUNT_FREQUENCY: 'Account Frequency',
  USER_TREND_OVERTIME: 'User Trend Overtime',
  DEVICE_USAGE: 'Device Usage',
  VISIT_BY_LOCATION: 'Visit by location',
  TOP_VIEW_CATEGORIES: 'Top View Categories',
  BREAKDOWN_BY_NUMBER_OF_CHILDREN: 'Breakdown by number of children'
}
export const DEFAULT_ANALYTIC_WIDGET_ITEMS_DATA: Record<
  string,
  AnalyticWidgetItem & {
    src?: string
    component: FC<AnalyticWidgetItemProps> | FC<any>
    isValid?: (data: any) => boolean
  }
> = {
  [ANALYTIC_WIDGET_ITEM_KEY.OVERALL_SCORECARD]: {
    src: '/images/chart/ReachabilityByChannels.png',
    title: ANALYTIC_WIDGET_ITEM_KEY.OVERALL_SCORECARD,
    fullWidth: true,
    fullHeight: false,
    component: AnalyticWidgetItemOverallScorecard,
    isValid: (data) => {
      const metrics = formatOverallScorecard(data)
      return !(!metrics?.length || metrics.every(({ total }) => !total))
    }
  },
  [ANALYTIC_WIDGET_ITEM_KEY.REACHABILITY_BY_CHANNELS]: {
    src: '/images/chart/spider.png',
    title: ANALYTIC_WIDGET_ITEM_KEY.REACHABILITY_BY_CHANNELS,
    fullWidth: false,
    fullHeight: true,
    component: AnalyticWidgetItemReachabilityByChannels,
    isValid: (data) =>
      Boolean(formatReachabilityByChannels(data)?.series?.length)
  },
  [ANALYTIC_WIDGET_ITEM_KEY.BREAKDOWN_BY_AGE_AND_GENDER]: {
    src: '/images/chart/AgeGenderAndSEC.png',
    title: ANALYTIC_WIDGET_ITEM_KEY.BREAKDOWN_BY_AGE_AND_GENDER,
    fullWidth: false,
    fullHeight: true,
    component: AnalyticWidgetItemBreakdownByAgeAndGender,
    isValid: (data) =>
      Boolean(formatBreakdownByAgeAndGender(data)?.series?.length)
  },
  [ANALYTIC_WIDGET_ITEM_KEY.BREAKDOWN_BY_SEC_CLASS]: {
    src: '/images/chart/AgeGenderAndSEC.png',
    title: ANALYTIC_WIDGET_ITEM_KEY.BREAKDOWN_BY_SEC_CLASS,
    fullWidth: false,
    fullHeight: true,
    component: AnalyticWidgetItemBreakdownBySECClass,
    isValid: (data) => Boolean(formatBreakdownBySECClass(data)?.series?.length)
  },
  [ANALYTIC_WIDGET_ITEM_KEY.ACCOUNT_FREQUENCY]: {
    src: '/images/chart/AccountFrequency1.png',
    title: ANALYTIC_WIDGET_ITEM_KEY.ACCOUNT_FREQUENCY,
    fullWidth: true,
    fullHeight: true,
    component: AnalyticWidgetItemUnderConstruction
  },
  [ANALYTIC_WIDGET_ITEM_KEY.USER_TREND_OVERTIME]: {
    src: '/images/chart/TrendOverTime.png',
    title: ANALYTIC_WIDGET_ITEM_KEY.USER_TREND_OVERTIME,
    fullWidth: false,
    fullHeight: false,
    component: AnalyticWidgetItemUnderConstruction
  },
  [ANALYTIC_WIDGET_ITEM_KEY.DEVICE_USAGE]: {
    src: '/images/chart/FrequencyUser.png',
    title: ANALYTIC_WIDGET_ITEM_KEY.DEVICE_USAGE,
    fullWidth: false,
    fullHeight: true,
    component: AnalyticWidgetItemDeviceUsage,
    isValid: (data) => Boolean(formatDeviceUsage(data)?.series?.length)
  },
  [ANALYTIC_WIDGET_ITEM_KEY.VISIT_BY_LOCATION]: {
    src: '/images/chart/Location.png',
    title: ANALYTIC_WIDGET_ITEM_KEY.VISIT_BY_LOCATION,
    fullWidth: false,
    fullHeight: true,
    component: AnalyticWidgetItemVisitByLocation,
    isValid: (data) => {
      // TODO
      return Boolean(Object.values(data.visit_city || {}).length)
      // return false
    }
  },
  [ANALYTIC_WIDGET_ITEM_KEY.TOP_VIEW_CATEGORIES]: {
    src: '/images/chart/AccountFrequency.png',
    title: ANALYTIC_WIDGET_ITEM_KEY.TOP_VIEW_CATEGORIES,
    fullWidth: false,
    fullHeight: true,
    component: AnalyticWidgetItemTopViewCategorise,
    isValid: (data) => Boolean(formatTopViewCategorise(data)?.series?.length)
  },
  [ANALYTIC_WIDGET_ITEM_KEY.BREAKDOWN_BY_NUMBER_OF_CHILDREN]: {
    src: '/images/chart/BREAKDOWN_BY_NUMBER_OF_CHILDREN.png',
    title: ANALYTIC_WIDGET_ITEM_KEY.BREAKDOWN_BY_NUMBER_OF_CHILDREN,
    fullWidth: false,
    fullHeight: true,
    component: AnalyticWidgetItemBreakdownByNumberOfChildren,
    isValid: (data) => formatBreakdownByNumberOfChildren(data)
  }
}

export const getQuerykey = (
  widget: string,
  segment_id: string | number | undefined,
  start_date: string | undefined,
  end_date: string | undefined
) => {
  return `${widget}${segment_id}${start_date}${end_date}`
}
