import {
  AnalyticWidgetItemProps,
  usePeriodLabel
} from '../AnalyticWidgetItem.helpers'
import { highchartOptions } from './helpers'
import Highcharts from 'highcharts'
import HighchartsHeatMap from 'highcharts/modules/heatmap'
import HighchartsTreeMap from 'highcharts/modules/treemap'
import { FC, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

// const data = {
//   count_1_child: 123,
//   count_2_child: 24,
//   count_3_child: 55,
//   count_4_child: 90,
//   count_4_plus_child: 75
// }

const StyledWrap = styled.div`
  .highcharts-title {
    display: none;
  }
`

export const AnalyticWidgetItemBreakdownByNumberOfChildren: FC<
  AnalyticWidgetItemProps
> = (props) => {
  const { data, item } = props

  const chartRef = useRef<Highcharts.Chart | null>(null)
  const label = usePeriodLabel(item?.query?.period)
  const [isShow, setIsShow] = useState(false)

  useEffect(() => {
    HighchartsHeatMap(Highcharts)
    HighchartsTreeMap(Highcharts)
    setTimeout(() => {
      setIsShow(true)
    }, 300)
  }, [])

  useEffect(() => {
    if (!isShow) return

    const {
      count_1_child = 0,
      count_2_child = 0,
      count_3_child = 0,
      count_4_child = 0,
      count_4_plus_child = 0
    } = data
    const min = Math.min(
      count_1_child,
      count_2_child,
      count_3_child,
      count_4_child,
      count_4_plus_child
    )
    const max = Math.max(
      count_1_child,
      count_2_child,
      count_3_child,
      count_4_child,
      count_4_plus_child
    )

    Highcharts.chart(
      'container-tree-map',
      highchartOptions({
        min,
        max,
        count_1_child,
        count_2_child,
        count_3_child,
        count_4_child,
        count_4_plus_child
      }),
      (chart) => {
        chartRef.current = chart
      }
    )
  }, [data, isShow])

  useEffect(() => {
    ;(window as any).ahihi = chartRef.current
    chartRef.current?.reflow()
  }, [props.fullWidth])

  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between">
        <h4 className="period mb-0 text-gray600">{label}</h4>
      </div>
      <StyledWrap className="flex-1 min-h-0">
        <div id="container-tree-map"></div>
      </StyledWrap>
    </div>
  )
}

export const formatBreakdownByNumberOfChildren = (data: any) => {
  const {
    count_1_child = 0,
    count_2_child = 0,
    count_3_child = 0,
    count_4_child = 0,
    count_4_plus_child = 0
  } = data
  const total =
    count_1_child +
    count_2_child +
    count_3_child +
    count_4_child +
    count_4_plus_child
  return total > 0
}
