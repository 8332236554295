import { AnalyticWidgetItemUnderConstruction } from '../../AnalyticWidgetItem'
import {
  ANALYTIC_WIDGET_ITEM_KEY,
  DEFAULT_ANALYTIC_WIDGET_ITEMS_DATA,
  getQuerykey,
  useAnalyticWidgetMuuri
} from '../AnalyticWidgetMuuri.helpers'
import { getAnalyticTemplateWidgetItemData } from './AnalyticWidgetMuuriItem.helpers'
import { StyledAnalyticWidgetMuuriItem } from './AnalyticWidgetMuuriItem.styled'
import Icon from '@ant-design/icons'
import { Button, Dropdown } from 'antd'
import cn from 'classnames'
import { Loading } from 'components/atoms/loading'
import { Download, DragIndicator } from 'icons'
import { Collapse, Edit, Expand, Kebab, TrashBin } from 'icons/V2'
import { AnalyticTemplateWidgetItem } from 'interfaces/analytic'
import { useCallback, useEffect, useMemo, useState } from 'react'

export type AnalyticWidgetMuuriItemProps = {
  item: AnalyticTemplateWidgetItem
  onChangeItem?: (item: AnalyticTemplateWidgetItem) => void
  onClickDropdown?: (key: string, record?: AnalyticTemplateWidgetItem) => void
}
export const AnalyticWidgetMuuriItem = (
  props: AnalyticWidgetMuuriItemProps
) => {
  const { query, dataQuery } = useAnalyticWidgetMuuri()
  const { item, onChangeItem, onClickDropdown } = props
  const { data, widget } = item
  const { fullWidth: fullWidthData, fullHeight, title: titleData } = data || {}
  const [fullWidth, setFullWidth] = useState(fullWidthData)
  const [loading, setLoading] = useState(false)
  const [downloadImage, setDownloadImage] = useState(false)

  const dataQueryKey = getQuerykey(
    widget,
    query?.segment_id,
    query?.start_date,
    query?.end_date
  )
  const widgetData = useMemo(() => {
    if (widget in DEFAULT_ANALYTIC_WIDGET_ITEMS_DATA) {
      return DEFAULT_ANALYTIC_WIDGET_ITEMS_DATA[
        widget as keyof typeof DEFAULT_ANALYTIC_WIDGET_ITEMS_DATA
      ]
    }
    return {
      title: 'Widget title',
      fullWidth: false,
      fullHeight: false,
      component: AnalyticWidgetItemUnderConstruction
    }
  }, [widget])
  const WidgetComponent = useMemo(
    () => widgetData.component,
    [widgetData.component]
  )
  const title = useMemo(() => {
    return titleData || widgetData?.title
  }, [titleData, widgetData?.title])
  const handleChangeSize = useCallback(() => {
    const newFullWidth = !fullWidth
    setFullWidth(newFullWidth)
    onChangeItem?.({ ...item, data: { ...item.data, fullWidth: newFullWidth } })
  }, [fullWidth, item, onChangeItem])
  const handleClickDropdown = useCallback(
    (menuInfo) => {
      if (menuInfo.key !== 'export') {
        onClickDropdown?.(menuInfo.key, item)
      } else {
        setDownloadImage(true)
        setTimeout(() => setDownloadImage(false), 100)
      }
    },
    [onClickDropdown, item]
  )

  useEffect(() => {
    if (!(dataQueryKey in dataQuery) && query) {
      ;(async () => {
        setLoading(true)
        await getAnalyticTemplateWidgetItemData({
          ...query,
          widget
        })
        setLoading(false)
      })()
    }
  }, [
    widget,
    query?.end_date,
    query?.start_date,
    query?.segment_id,
    dataQueryKey,
    dataQuery,
    query
  ])

  const isVisitLocation = widget === ANALYTIC_WIDGET_ITEM_KEY.VISIT_BY_LOCATION

  return (
    <StyledAnalyticWidgetMuuriItem className={cn({ fullWidth, fullHeight })}>
      <div className="analyticWidgetMuuriItem--container flex flex-col overflow-hidden">
        <div
          className="analyticWidgetMuuriItem--handle w-[30px] h-[20px] flex items-center justify-center rounded-b-sm absolute top-0 left-1/2 -transform-x-1/2 text-xl text-gray300 hover:text-gray500 transition"
          data-html2canvas-ignore>
          <DragIndicator />
        </div>
        <div className="analyticWidgetMuuriItem--header flex items-start w-full mb-4">
          <h3
            title={title}
            className="analyticWidgetMuuriItem--title flex-1 min-w-0 mb-0 text-gray800 text-lg font-semibold overflow-hidden text-ellipsis whitespace-nowrap">
            {title}
          </h3>
          <div className="flex gap-4" data-html2canvas-ignore>
            <Button
              type="link"
              className="analyticWidgetMuuriItem--button"
              icon={<Icon component={fullWidth ? Collapse : Expand} />}
              onClick={handleChangeSize}
            />
            <Dropdown
              destroyPopupOnHide
              trigger={['click']}
              menu={{
                items: [
                  {
                    key: 'edit',
                    label: 'Edit',
                    icon: <Icon component={Edit} className="text-lg mr-4" />,
                    className: 'py-2 px-4'
                  },
                  isVisitLocation
                    ? [
                        {
                          key: 'export',
                          label: 'Download Image',
                          icon: (
                            <Icon
                              component={Download}
                              className="text-lg mr-4"
                            />
                          ),
                          className: 'py-2 px-4'
                        }
                      ]
                    : [],
                  {
                    key: 'remove',
                    label: 'Remove',
                    icon: (
                      <Icon component={TrashBin} className="text-lg mr-4" />
                    ),
                    className: 'py-2 px-4 text-red800'
                  }
                ].flat(),
                onClick: handleClickDropdown,
                className: 'py-0 overflow-hidden rounded-lg min-w-[168px]'
              }}>
              <Button
                type="link"
                className="analyticWidgetMuuriItem--button"
                icon={<Icon component={Kebab} />}
              />
            </Dropdown>
          </div>
        </div>
        <div className="analyticWidgetMuuriItem--body relative flex-1 min-h-0">
          {WidgetComponent && (
            <WidgetComponent
              loading={loading}
              data={dataQuery?.[dataQueryKey] || {}}
              item={item}
              onChangeItem={onChangeItem}
              downloadImage={downloadImage}
              fullWidth={fullWidth}
            />
          )}
        </div>
        <Loading
          spinning={loading}
          className={cn(
            'absolute inset-0 z-10 flex items-center justify-center pointer-events-none opacity-0',
            'before:content-[""] before:absolute before:inset-0 before:z-[-1] before:bg-neutral50 before:opacity-50',
            { 'pointer-events-auto opacity-100': loading }
          )}
        />
      </div>
    </StyledAnalyticWidgetMuuriItem>
  )
}
