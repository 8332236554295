export const highchartOptions = ({
  min,
  max,
  count_1_child,
  count_2_child,
  count_3_child,
  count_4_child,
  count_4_plus_child
}: Record<string, number>) =>
  ({
    series: [
      {
        type: 'treemap',
        layoutAlgorithm: 'squarified',
        clip: false,
        borderColor: 'transparent',
        data: [
          {
            label: '1 Child',
            value: count_1_child,
            color: '#45A4FF'
          },
          {
            label: '2 Children',
            value: count_2_child,
            color: '#65B5FF'
          },
          {
            label: '3 Children',
            value: count_3_child,
            color: '#91CAFF'
          },
          {
            label: '4 Children',
            value: count_4_child,
            color: '#BCDEFF'
          },
          {
            label: '> 4 Children',
            value: count_4_plus_child,
            color: '#E3F2FF'
          }
        ]
      }
    ],
    title: {
      text: 'Parenting - Total Children'
    },
    tooltip: {
      useHTML: true,
      pointFormat: '{point.label}: <b>{point.value}</b>'
    }
  } as Highcharts.Options)
